import React from "react"
import { Link } from "gatsby"
import {Container,Row,Col} from "react-bootstrap";
import Layout from "../components/layout"
import TradesmenImage1 from "../components/imagesComponents/TradesmenImage1"
import TradesmenImage2 from "../components/imagesComponents/TradesmenImage2"
import SEO from "../components/seo"
import tradesmenStyle from '../styles/tradesmen.module.css'

const background = {backgroundColor:'#F8F9F9',paddingTop:'50px',paddingBottom:'50px'}

const TradesmenPage = () => (
  <Layout>
    <SEO title="tradesmen" />
    <body className={tradesmenStyle.font} >
    <Container style={background}>
      <Row>
        <Col sm={12} md={{span:10, offset:1}}>
          <div style={{textAlign:'justify'}}>
            <h3 className={tradesmenStyle.title} style={{color:'#E9BF3C'}}>Tradesmen Service</h3> 
            <p>If your plan is to age-in-place and you need to make modifications to your current home, we can
            assist in making your changes come true. If you are selling or downsizing a home our team will
            provide you with a list of possible enhancements or project completions that would enable you
            to sell your home quickly and at a higher price.</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={1}/>
        <Col sm={12} md={5} className={tradesmenStyle.secondparagraph}>
          <p style={{textAlign:'justify'}} >Realtors know a Buyer will opt out of viewing a home for sale if 
          the exterior is rough. Curb appeal is particularly important because a Buyer believes the outside 
          is an indicator of the condition of the interior. Our team can offer various levels of landscaping 
          to get the best exterior presentation for your home geared toward getting them through the 
          front door. Leave it to us to coordinate landscaping contractors and nurseries in a timely fashion. 
          From start to finish the projects are scheduled so you don’t have to. If the home is listed, 
          these services can commonly be settled at close of sale to minimize out of pocket expenses.</p>
        </Col>
        <Col sm={12} md={5}>
          {/* <div className={tradesmenStyle.boxImage1}><TradesmenImage1 /></div> */}
          <div style={{ 
                backgroundImage:`url("../../images/tradesmen1.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center',
                backgroundSize:"cover",
                width:'100%'}} className={tradesmenStyle.img}/>
        </Col>
        <Col sm={12} md={5} className={tradesmenStyle.secondparagraphMovil}>
          <p style={{textAlign:'justify'}} >Realtors know a Buyer will opt out of viewing a home for sale if 
          the exterior is rough. Curb appeal is particularly important because a Buyer believes the outside 
          is an indicator of the condition of the interior. Our team can offer various levels of landscaping 
          to get the best exterior presentation for your home geared toward getting them through the 
          front door. Leave it to us to coordinate landscaping contractors and nurseries in a timely fashion. 
          From start to finish the projects are scheduled so you don’t have to. If the home is listed, 
          these services can commonly be settled at close of sale to minimize out of pocket expenses.</p>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
      <Row>
        <Col sm={12} md={1}/>
        <Col sm={12} md={5}>
          {/* <div className={tradesmenStyle.boxImage1}><TradesmenImage2 /></div> */}
          <div style={{ 
                backgroundImage:`url("../../images/tradesmen2.jpg")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition:'center',
                backgroundSize:"cover",
                width:'100%'}} className={tradesmenStyle.img}/>
        </Col>
        <Col sm={12} md={5}>
        <p style={{textAlign:'justify'}}>Buyers will drive by a property for sale and if the exterior is rough, will often decide not to view
            the home. Curb appeal is particularly important because a Buyer believes the outside is an
            indicator of the condition of the interior. Our team can offer various levels of landscaping to get
            the best exterior presentation for your home geared toward getting them through the front door.
            Leave it to us to coordinate landscaping contractors and nurserys in a timely fashion.
            From start to finish the projects are scheduled so you don’t have to. If the home is listed, these
            services can commonly be settled at close of sale to minimize out of pocket expenses.
            </p>
        </Col>
        <Col sm={12} md={1}/>
      </Row>
    </Container>
    </body>
  </Layout>
)

export default TradesmenPage